.digs-youtube-player {
  width: 100%;
  height: 200px;

  &-container {
    overflow: hidden;
    border-radius: 16px;
  }
}

@media (min-width: 768px) {
  .digs-youtube-player {
    height: 400px;
  }
}
