@import './custom-bulma';

/* stylelint-disable */
body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100%;
}

::selection {
	background: #ffffff;
	color: #fff;
}

body {
	color: #041f47;
	font-family: Gilroy, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont,
		Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif;
}

main {
	position: relative;
}

p {
	line-height: 1.7;
}

a {
	text-decoration: none;
}

input {
	font-family: inherit;
	font-size: inherit;
}

input:placeholder {
	color: #94a0af;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 300;
	src: url('./assets/fonts/Gilroy-Light.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-Light.woff') format('woff'),
		url('./assets/fonts/Gilroy-Light.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: italic;
	font-weight: 300;
	src: url('./assets/fonts/Gilroy-LightItalic.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-LightItalic.woff') format('woff'),
		url('./assets/fonts/Gilroy-LightItalic.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 500;
	src: url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-Medium.woff') format('woff'),
		url('./assets/fonts/Gilroy-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: italic;
	font-weight: 500;
	src: url('./assets/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-MediumItalic.woff') format('woff'),
		url('./assets/fonts/Gilroy-MediumItalic.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 400;
	src: url('./assets/fonts/Gilroy-Regular.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-Regular.woff') format('woff'),
		url('./assets/fonts/Gilroy-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: italic;
	font-weight: 400;
	src: url('./assets/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-RegularItalic.woff') format('woff'),
		url('./assets/fonts/Gilroy-RegularItalic.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 700;
	src: url('./assets/fonts/Gilroy-Bold.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-Bold.woff') format('woff'),
		url('./assets/fonts/Gilroy-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: italic;
	font-weight: 700;
	src: url('./assets/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-BoldItalic.woff') format('woff'),
		url('./assets/fonts/Gilroy-BoldItalic.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 800;
	src: url('./assets/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-ExtraBold.woff') format('woff'),
		url('./assets/fonts/Gilroy-ExtraBold.otf') format('opentype');
}

@font-face {
	font-family: 'Gilroy';
	font-style: normal;
	font-weight: 900;
	src: url('./assets/fonts/Gilroy-Heavy.woff2') format('woff2'),
		url('./assets/fonts/Gilroy-Heavy.woff') format('woff'),
		url('./assets/fonts/Gilroy-Heavy.otf') format('opentype');
}

.filepond--root {
	font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
		sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

.filepond--panel-root {
	background-color: #fafafa !important;
}

.ReactModal__Body--open {
	overflow-y: hidden;
}

@media (min-width: 600px) {
	/* style scrollbar */
	:not(.default-scrollbar)::-webkit-scrollbar {
		width: 6px;
		height: 6px;

		&-track {
			background: #ffffff;
			border-radius: 6px;
		}

		&-thumb {
			background: rgba(16, 40, 79, 0.4);
			border-radius: 6px;
		}
	}
}

// Hide reCaptcha badge
.grecaptcha-badge {
	display: none !important;
}

.swal2-popup {
	z-index: 180000;
}