.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:rgba(28, 26, 43, 0.8);
  z-index: 160;
}

.modal-full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 160;
  background-color: #fafafa;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 700px) {
  .overlay {
    background-color:rgba(28, 26, 43, 0.8);
  }
  .modal-full {
    padding: 20px;
  }
}

.modal {
  position: static;
  width: 43em;
  border-radius: 12px;
  border: none;
  max-height: 100vh;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  padding: 0;
  outline: none;
  /*margin-top: -5vh;*/

  -webkit-box-shadow: 6px 6px 13px -1px rgba(0,0,0,0.5);
-moz-box-shadow: 6px 6px 13px -1px rgba(0,0,0,0.5);
box-shadow: 6px 6px 13px -1px rgba(0,0,0,0.5);
}

@media (max-width: 700px) {
  .modal {
    box-shadow: none;
  }

@media (max-width: 812px) {
  .modal {
    overflow-y: scroll;
    margin: 20px;
  }
}

@media (max-width: 374px) {
  .modal {
    border-radius: 0px;
    margin: 0px;
  }
}

  /*.overlay {
   /background-color: #fff;
  }*/
}

.swal2-popup {
  border-radius: 20px;
  box-shadow: 0 0.25em 2em 0 rgba(0, 0, 0, 0.05);
  padding: 5em;
  width: 44em;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.swal2-title {
  font-size: 23px;
}

.swal2-confirm.swal2-styled.confirm-button-class {
  border-radius: 20px;
  padding: 15px;
  width: 140px;
  background-color: #FF0052;
  box-shadow: 0 0.25em 2em 0 rgba(0, 0, 0, 0.05);
}
