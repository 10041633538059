$color-pink: #ff0052;

.input-range__track {
  transition: none !important;
}

.input-range__slider-container {
  transition: none !important;
}
.input-range__track--active {
  background-color: $color-pink !important;
}
.input-range__slider {
  width: 20px !important;
  height: 20px !important;
  top: -2px !important;
  margin-left: -10px !important;
  background-color: $color-pink !important;
  border-color: $color-pink !important;
}
.input-range__label--value {
  display: none !important;
}
.input-range__label--max {
  display: none !important;
}
.input-range__label--min {
  display: none !important;
}
